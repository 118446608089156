import React from "react";
import { Link } from "gatsby";

import * as MaterialUI from "@material-ui/core";
import * as MaterialUILab from "@material-ui/lab";
import Icon from "@mdi/react";
import { mdiAccount } from "@mdi/js";

import Layout from "../components/Layout";

const SecondPage = () => (
  <Layout>
    <Link to="/">Go back to the homepage</Link>
    <main>
      <Components />
    </main>
  </Layout>
);

const TestIcon = () => <Icon size={1} path={mdiAccount} />;

const Components = () => {
  const classes = useStyles();

  return (
    <MaterialUI.Container className={classes.root}>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Button variant="contained">Default</MaterialUI.Button>
        <MaterialUI.Button variant="contained" color="primary">
          Primary
        </MaterialUI.Button>
        <MaterialUI.Button variant="contained" color="secondary">
          Secondary
        </MaterialUI.Button>
        <MaterialUI.Button variant="contained" disabled>
          disabled
        </MaterialUI.Button>
        <MaterialUI.Button
          variant="contained"
          color="primary"
          href="#contained-buttons"
        >
          Link
        </MaterialUI.Button>
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Button variant="text">Default</MaterialUI.Button>
        <MaterialUI.Button variant="text" color="primary">
          Primary
        </MaterialUI.Button>
        <MaterialUI.Button variant="text" color="secondary">
          Secondary
        </MaterialUI.Button>
        <MaterialUI.Button variant="text" disabled>
          disabled
        </MaterialUI.Button>
        <MaterialUI.Button
          variant="text"
          color="primary"
          href="#contained-buttons"
        >
          Link
        </MaterialUI.Button>
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Button variant="outlined">Default</MaterialUI.Button>
        <MaterialUI.Button variant="outlined" color="primary">
          Primary
        </MaterialUI.Button>
        <MaterialUI.Button variant="outlined" color="secondary">
          Secondary
        </MaterialUI.Button>
        <MaterialUI.Button variant="outlined" disabled>
          disabled
        </MaterialUI.Button>
        <MaterialUI.Button
          variant="outlined"
          color="primary"
          href="#contained-buttons"
        >
          Link
        </MaterialUI.Button>
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Button variant="contained" size={"small"}>
          Small
        </MaterialUI.Button>
        <MaterialUI.Button variant="contained" size={"medium"}>
          Medium
        </MaterialUI.Button>
        <MaterialUI.Button variant="contained" size={"large"}>
          Large
        </MaterialUI.Button>
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Button variant="text">
          <Icon path={mdiAccount} size={1} />
        </MaterialUI.Button>
        <MaterialUI.Button variant="text" color="primary">
          <Icon size={1} path={mdiAccount} />
        </MaterialUI.Button>
        <MaterialUI.Button variant="text" color="secondary">
          <Icon size={1} path={mdiAccount} />
        </MaterialUI.Button>
        <MaterialUI.Button variant="text" disabled>
          <Icon size={1} path={mdiAccount} />
        </MaterialUI.Button>
        <MaterialUI.Button
          variant="text"
          color="primary"
          href="#contained-buttons"
        >
          <Icon size={1} path={mdiAccount} />
        </MaterialUI.Button>
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Button variant="contained">
          <Icon path={mdiAccount} size={1} /> Default
        </MaterialUI.Button>
        <MaterialUI.Button variant="contained" color="primary">
          <Icon size={1} path={mdiAccount} /> Primary
        </MaterialUI.Button>
        <MaterialUI.Button variant="contained" color="secondary">
          <Icon size={1} path={mdiAccount} /> Secondary
        </MaterialUI.Button>
        <MaterialUI.Button variant="contained" disabled>
          <Icon size={1} path={mdiAccount} /> Disabled
        </MaterialUI.Button>
        <MaterialUI.Button
          variant="contained"
          color="primary"
          href="#contained-buttons"
        >
          <Icon size={1} path={mdiAccount} /> Link
        </MaterialUI.Button>
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.groupVertical}>
        <MaterialUI.ButtonGroup color="primary">
          <MaterialUI.Button>Primary outlined</MaterialUI.Button>
          <MaterialUI.Button>Primary outlined</MaterialUI.Button>
          <MaterialUI.Button>Primary outlined</MaterialUI.Button>
        </MaterialUI.ButtonGroup>
        <MaterialUI.ButtonGroup color="primary" variant="contained">
          <MaterialUI.Button>Primary contained</MaterialUI.Button>
          <MaterialUI.Button>Primary contained</MaterialUI.Button>
          <MaterialUI.Button>Primary contained</MaterialUI.Button>
        </MaterialUI.ButtonGroup>
        <MaterialUI.ButtonGroup color="primary" variant="text">
          <MaterialUI.Button>Primary text</MaterialUI.Button>
          <MaterialUI.Button>Primary text</MaterialUI.Button>
          <MaterialUI.Button>Primary text</MaterialUI.Button>
        </MaterialUI.ButtonGroup>
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.groupVertical}>
        <MaterialUI.ButtonGroup color="secondary">
          <MaterialUI.Button>Secondary outlined</MaterialUI.Button>
          <MaterialUI.Button>Secondary outlined</MaterialUI.Button>
          <MaterialUI.Button>Secondary outlined</MaterialUI.Button>
        </MaterialUI.ButtonGroup>
        <MaterialUI.ButtonGroup color="secondary" variant="contained">
          <MaterialUI.Button>Secondary contained</MaterialUI.Button>
          <MaterialUI.Button>Secondary contained</MaterialUI.Button>
          <MaterialUI.Button>Secondary contained</MaterialUI.Button>
        </MaterialUI.ButtonGroup>
        <MaterialUI.ButtonGroup color="secondary" variant="text">
          <MaterialUI.Button>Secondary text</MaterialUI.Button>
          <MaterialUI.Button>Secondary text</MaterialUI.Button>
          <MaterialUI.Button>Secondary text</MaterialUI.Button>
        </MaterialUI.ButtonGroup>
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Checkbox checked />
        <MaterialUI.Checkbox checked color="primary" />
        <MaterialUI.Checkbox />
        <MaterialUI.Checkbox disabled />
        <MaterialUI.Checkbox disabled checked />
        <MaterialUI.Checkbox defaultChecked indeterminate />
        <MaterialUI.Checkbox defaultChecked color="default" />
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Fab color="primary">
          <Icon size={1} path={mdiAccount} />
        </MaterialUI.Fab>
        <MaterialUI.Fab color="secondary">
          <Icon size={1} path={mdiAccount} />
        </MaterialUI.Fab>
        <MaterialUI.Fab color="default">
          <Icon size={1} path={mdiAccount} />
        </MaterialUI.Fab>
        <MaterialUI.Fab variant="extended">
          <Icon size={1} path={mdiAccount} /> Thing
        </MaterialUI.Fab>
        <MaterialUI.Fab disabled>
          <Icon size={1} path={mdiAccount} />
        </MaterialUI.Fab>
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Radio checked />
        <MaterialUI.Radio />
        <MaterialUI.Radio checked color="primary" />
        <MaterialUI.Radio color="primary" />
        <MaterialUI.Radio checked color={"default"} />
        <MaterialUI.Radio color={"default"} />
        <MaterialUI.Radio disabled checked />
        <MaterialUI.Radio disabled />
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.groupVertical}>
        <MaterialUI.Slider
          valueLabelDisplay={"auto"}
          defaultValue={30}
          step={10}
          marks
          min={10}
          max={110}
        />
        <MaterialUI.Slider
          valueLabelDisplay={"auto"}
          defaultValue={70}
          step={10}
          marks
          min={10}
          max={110}
          color={"secondary"}
        />
        <MaterialUI.Slider
          valueLabelDisplay={"auto"}
          defaultValue={70}
          step={10}
          marks
          min={10}
          max={110}
          disabled
        />
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Switch checked />
        <MaterialUI.Switch />
        <MaterialUI.Switch checked color="primary" />
        <MaterialUI.Switch color="primary" />
        <MaterialUI.Switch checked color={"default"} />
        <MaterialUI.Switch color={"default"} />
        <MaterialUI.Switch disabled checked />
        <MaterialUI.Switch disabled />
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Container className={classes.groupVertical}>
          <MaterialUI.TextField placeholder={"Primary"} />
          <MaterialUI.TextField color="secondary" placeholder={"Secondary"} />
          <MaterialUI.TextField placeholder={"Disabled"} disabled />
          <MaterialUI.TextField placeholder={"Error"} error />
          <MaterialUI.TextField placeholder={"Required"} required />
        </MaterialUI.Container>
        <MaterialUI.Container className={classes.groupVertical}>
          <MaterialUI.TextField
            variant="outlined"
            placeholder={"Primary Outlined"}
          />
          <MaterialUI.TextField
            variant="outlined"
            color="secondary"
            placeholder={"Secondary Outlined"}
          />
          <MaterialUI.TextField
            variant="outlined"
            placeholder={"Disabled Outlined"}
            disabled
          />
          <MaterialUI.TextField
            variant="outlined"
            placeholder={"Error Outlined"}
            error
          />
          <MaterialUI.TextField
            variant="outlined"
            placeholder={"Required Outlined"}
            required
          />
        </MaterialUI.Container>
        <MaterialUI.Container className={classes.groupVertical}>
          <MaterialUI.TextField
            variant="filled"
            placeholder={"Primary filled"}
          />
          <MaterialUI.TextField
            variant="filled"
            color="secondary"
            placeholder={"Secondary filled"}
          />
          <MaterialUI.TextField
            variant="filled"
            placeholder={"Disabled filled"}
            disabled
          />
          <MaterialUI.TextField
            variant="filled"
            placeholder={"Error filled"}
            error
          />
          <MaterialUI.TextField
            variant="filled"
            placeholder={"Required filled"}
            required
          />
        </MaterialUI.Container>
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.groupVertical}>
        <MaterialUI.BottomNavigation value="Favorites" showLabels>
          <MaterialUI.BottomNavigationAction
            label="Recents"
            value="Recents"
            icon={<TestIcon />}
          />
          <MaterialUI.BottomNavigationAction
            label="Favorites"
            value="Favorites"
            icon={<TestIcon />}
          />
          <MaterialUI.BottomNavigationAction
            label="Nearby"
            value="Nearby"
            icon={<TestIcon />}
          />
        </MaterialUI.BottomNavigation>
        <MaterialUI.Breadcrumbs aria-label="breadcrumb">
          <MaterialUI.Link color="inherit" href="/">
            Material-UI
          </MaterialUI.Link>
          <MaterialUI.Link
            color="inherit"
            href="/getting-started/installation/"
          >
            Core
          </MaterialUI.Link>
          <MaterialUI.Typography color="textPrimary">
            Breadcrumb
          </MaterialUI.Typography>
        </MaterialUI.Breadcrumbs>
        <MaterialUI.AppBar position="static">
          <MaterialUI.Toolbar>
            <MaterialUI.IconButton edge="start" color="inherit">
              <TestIcon />
            </MaterialUI.IconButton>
            <MaterialUI.Typography variant="h6" noWrap>
              CaffeinatedCM
            </MaterialUI.Typography>
            <div>
              <MaterialUI.InputBase
                placeholder="Search"
                startAdornment={<TestIcon />}
              />
            </div>
            <div style={{ flexGrow: 1 }} />
            <div>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={17} color="primary">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={4} color="secondary">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={3} color="default">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={-1} color="error">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
            </div>
          </MaterialUI.Toolbar>
        </MaterialUI.AppBar>
        <MaterialUI.AppBar position="static" color="default">
          <MaterialUI.Toolbar>
            <MaterialUI.IconButton edge="start" color="inherit">
              <TestIcon />
            </MaterialUI.IconButton>
            <MaterialUI.Typography variant="h6" noWrap>
              CaffeinatedCM
            </MaterialUI.Typography>
            <div>
              <MaterialUI.InputBase
                placeholder="Search"
                startAdornment={<TestIcon />}
              />
            </div>
            <div style={{ flexGrow: 1 }} />
            <div>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={17} color="primary">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={4} color="secondary">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={3} color="default">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={-1} color="error">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
            </div>
          </MaterialUI.Toolbar>
        </MaterialUI.AppBar>
        <MaterialUI.AppBar position="static" color="secondary">
          <MaterialUI.Toolbar>
            <MaterialUI.IconButton edge="start" color="inherit">
              <TestIcon />
            </MaterialUI.IconButton>
            <MaterialUI.Typography variant="h6" noWrap>
              CaffeinatedCM
            </MaterialUI.Typography>
            <div>
              <MaterialUI.InputBase
                placeholder="Search"
                startAdornment={<TestIcon />}
              />
            </div>
            <div style={{ flexGrow: 1 }} />
            <div>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={17} color="primary">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={4} color="secondary">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={3} color="default">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={-1} color="error">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
            </div>
          </MaterialUI.Toolbar>
        </MaterialUI.AppBar>
        <MaterialUI.AppBar position="static" color="transparent">
          <MaterialUI.Toolbar>
            <MaterialUI.IconButton edge="start" color="inherit">
              <TestIcon />
            </MaterialUI.IconButton>
            <MaterialUI.Typography variant="h6" noWrap>
              CaffeinatedCM
            </MaterialUI.Typography>
            <div>
              <MaterialUI.InputBase
                placeholder="Search"
                startAdornment={<TestIcon />}
              />
            </div>
            <div style={{ flexGrow: 1 }} />
            <div>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={17} color="primary">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={4} color="secondary">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={3} color="default">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
              <MaterialUI.IconButton color="inherit">
                <MaterialUI.Badge badgeContent={-1} color="error">
                  <TestIcon />
                </MaterialUI.Badge>
              </MaterialUI.IconButton>
            </div>
          </MaterialUI.Toolbar>
        </MaterialUI.AppBar>
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Paper className={classes.paperTest}>
          Default Paper
        </MaterialUI.Paper>
        <MaterialUI.Paper square className={classes.paperTest}>
          Square paper
        </MaterialUI.Paper>
        <MaterialUI.Paper elevation={6} className={classes.paperTest}>
          Elevated Paper
        </MaterialUI.Paper>
        <MaterialUI.Paper variant="outlined" className={classes.paperTest}>
          Outlined Paper
        </MaterialUI.Paper>
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.CircularProgress />
        <MaterialUI.CircularProgress color="secondary" />
        <MaterialUI.CircularProgress value={75} variant="static" />
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.groupVertical}>
        <MaterialUI.LinearProgress />
        <MaterialUI.LinearProgress color="secondary" />
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Container className={classes.groupVertical}>
          <MaterialUILab.Alert severity="error">
            Error alert
          </MaterialUILab.Alert>
          <MaterialUILab.Alert severity="warning">
            Warning alert
          </MaterialUILab.Alert>
          <MaterialUILab.Alert severity="info">Info alert</MaterialUILab.Alert>
          <MaterialUILab.Alert severity="success">
            Success alert
          </MaterialUILab.Alert>
        </MaterialUI.Container>
        <MaterialUI.Container className={classes.groupVertical}>
          <MaterialUILab.Alert variant="filled" severity="error">
            Error alert
          </MaterialUILab.Alert>
          <MaterialUILab.Alert variant="filled" severity="warning">
            Warning alert
          </MaterialUILab.Alert>
          <MaterialUILab.Alert variant="filled" severity="info">
            Info alert
          </MaterialUILab.Alert>
          <MaterialUILab.Alert variant="filled" severity="success">
            Success alert
          </MaterialUILab.Alert>
        </MaterialUI.Container>
        <MaterialUI.Container className={classes.groupVertical}>
          <MaterialUILab.Alert variant="outlined" severity="error">
            Error alert
          </MaterialUILab.Alert>
          <MaterialUILab.Alert variant="outlined" severity="warning">
            Warning alert
          </MaterialUILab.Alert>
          <MaterialUILab.Alert variant="outlined" severity="info">
            Info alert
          </MaterialUILab.Alert>
          <MaterialUILab.Alert variant="outlined" severity="success">
            Success alert
          </MaterialUILab.Alert>
        </MaterialUI.Container>
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Chip label={"Chip"} />
        <MaterialUI.Chip variant={"outlined"} label={"Chip"} />
        <MaterialUI.Chip color="primary" label={"Chip"} />
        <MaterialUI.Chip
          color={"primary"}
          variant={"outlined"}
          label={"Chip"}
        />
        <MaterialUI.Chip color="secondary" label={"Chip"} />
        <MaterialUI.Chip
          color={"secondary"}
          variant={"outlined"}
          label={"Chip"}
        />
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Chip label={"Chip"} icon={<TestIcon />} />
        <MaterialUI.Chip
          variant={"outlined"}
          label={"Chip"}
          icon={<TestIcon />}
        />
        <MaterialUI.Chip color="primary" label={"Chip"} icon={<TestIcon />} />
        <MaterialUI.Chip
          color={"primary"}
          variant={"outlined"}
          label={"Chip"}
          icon={<TestIcon />}
        />
        <MaterialUI.Chip color="secondary" label={"Chip"} icon={<TestIcon />} />
        <MaterialUI.Chip
          color={"secondary"}
          variant={"outlined"}
          label={"Chip"}
          icon={<TestIcon />}
        />
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Chip
          label={"Chip"}
          onDelete={() => {
            console.log("delete");
          }}
        />
        <MaterialUI.Chip
          variant={"outlined"}
          label={"Chip"}
          onDelete={() => {
            console.log("delete");
          }}
        />
        <MaterialUI.Chip
          color="primary"
          label={"Chip"}
          onDelete={() => {
            console.log("delete");
          }}
        />
        <MaterialUI.Chip
          color={"primary"}
          variant={"outlined"}
          label={"Chip"}
          onDelete={() => {
            console.log("delete");
          }}
        />
        <MaterialUI.Chip
          color="secondary"
          label={"Chip"}
          onDelete={() => {
            console.log("delete");
          }}
        />
        <MaterialUI.Chip
          color={"secondary"}
          variant={"outlined"}
          label={"Chip"}
          onDelete={() => {
            console.log("delete");
          }}
        />
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.groupVertical}>
        <MaterialUI.Typography variant="h1">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography variant="h2">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography variant="h3">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography variant="h4">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography variant="h5">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography variant="h6">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography variant="subtitle1">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography variant="subtitle2">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography variant="body1">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography variant="body2">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography variant="button">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography variant="caption">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography variant="overline">
          CaffeinatedCM
        </MaterialUI.Typography>
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.groupVertical}>
        <MaterialUI.Typography color="primary" variant="h1">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography color="textPrimary" variant="h2">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography color="secondary" variant="h3">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography color="textSecondary" variant="h4">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography color="inherit" variant="h5">
          CaffeinatedCM
        </MaterialUI.Typography>
        <MaterialUI.Typography color="error" variant="h6">
          CaffeinatedCM
        </MaterialUI.Typography>
      </MaterialUI.Container>
      <MaterialUI.Container className={classes.group}>
        <MaterialUI.Typography variant="h5" component="div">
          <MaterialUILab.Skeleton />
        </MaterialUI.Typography>
        <MaterialUILab.Skeleton variant="circle" width={40} height={40} />
        <MaterialUILab.Skeleton variant="rect" width={210} height={118} />
      </MaterialUI.Container>
    </MaterialUI.Container>
  );
};

const useStyles = MaterialUI.makeStyles(theme => ({
  root: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-around",
    backgroundColor: theme.palette.background.default,
  },
  group: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-around",
    margin: `${theme.spacing(2)}px 0`,
  },
  groupVertical: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-around",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  paperTest: {
    width: "200px",
    height: "200px",
  },
}));

export default SecondPage;
